<template>
    <div class="voucher-category-radio">
      <el-radio v-model="radio" label="all">Все</el-radio>
      <el-radio v-model="radio" label="special_offer">Акции</el-radio>
      <el-radio v-model="radio" label="treatment">Лечебные</el-radio>
      <el-radio v-model="radio" label="rest">На отдых</el-radio>
    </div>
</template>

<script>
  export default {
    props: {
        sequenceNumber: Number,
        roomNumber: Number,
        value: String
      },
    data () {
      return {
        radio: this.value,
      };
    },
    watch: {
      radio: function (val) {
        this.$emit("changeCategory", {"value": val, "sequenceNumber":this.sequenceNumber, "roomNumber":this.roomNumber})
        this.$emit("rerenderCards")
    }
    }
  }
</script>

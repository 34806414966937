<template>
    <el-table
        :data="tableData"
        label="Детали брони"
        style="width: 100%"
        :highlight-current-row="true"
    >
        <el-table-column prop="title" label="Детали брони" width="100%">
            <el-table-column prop="name" width="180px"> </el-table-column>
            <el-table-column prop="descr" width="" > </el-table-column>
        </el-table-column>
    </el-table>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props: {
        dates: String,
        guests: String,
        rooms: String,
        tariffs: String,
        totalPrice: Number,
    },
    data() {
        return {
			
            tableData: [
                {
                    name: "Даты",
                    descr: this.dates,
                },
                {
                    name: "Гости",
                    descr: this.guests,
                },
                {
                    name: "Номера",
                    descr: this.rooms,
                },
                {
                    name: "Путевки",
                    descr: this.tariffs,
                },
                {
                    name: "Общая стоимость",
                    descr: this.totalPrice,
                },
            ],
        };
    },
    computed: {
        ...mapGetters([
            "BOOKING_INFO",
            "NIGHTS_INFO",
            "GUEST_PARAMS",
            "CHOOSEN_ROOMS",
            "CHOOSEN_TARIFFS",
			"DAYS"
        ])
    },
    
};
</script>

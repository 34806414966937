<template>
    <div v-if="isVisible()">
        <el-card
            class="tariff-card has-stickers"
            :class="{
                'active-card': isChoosen(tariff.tariffId, tabName, tariff.isRoom)
            }"
        >
            <div slot="header" class="clearfix" @click="clickMoreInfo(tariff, tabName, roomNumberIdx)">
                <h4>
                    {{ tariff.title }} <span v-if="!tariff.isRoom">(место в номере)</span>
                </h4>
                <el-button type="text">Подробнее</el-button>
            </div>
            <div class="tariff-text">
                <div class="column">
                    <ul>
                        <li v-if="tariff.treatment">
                            <i class="las la-heartbeat"></i>
                            Лечение: включено
                        </li>
                        <li class="disabled" v-else>
                            <i class="las la-heartbeat"></i>
                            Лечение: не включено
                        </li>
                        <li>
                            <i class="las la-utensils"></i>
                            Питание: {{ tariff.food }}
                        </li>
                        <li>
                            <i class="las la-undo-alt"></i>
                            Отмена брони: {{ tariff.cancel_reservaton }}
                        </li>
                        <li>
                            <i class="las la-credit-card"></i>
                            Оплата: {{ tariff.payment }}
                        </li>
                    </ul>
                </div>

                <div class="column">
                    <div class="price">
                        <div>
                            Стоимость за
                            {{ DAYS }} дней
                        </div>
                        <div class="price-numbers">
                            <div class="old-price" v-if="tariff.discount">
                                {{ calculateOldPrice }} ₽
                            </div>
                            <div class="actual-price">
                                {{ tariff.fullPrice }}
                                ₽
                            </div>
                        </div>
                        <div class="sale" v-if="tariff.discount">
                            -{{ tariff.discount }}%
                        </div>
                    </div>
                    <el-button type="primary" class="choose-button" @click="choiceTariff(tariff)">
                        Выбрать
                    </el-button>
                    <el-button
                        class="choosen-button"
                        type="primary"
                        @click="choiceTariff(tariff)"
                    >
                        <i class="las la-check"></i>
                        Далее
                    </el-button>
                </div>
            </div>
            <div class="stickers" v-if="tariff.cashbackMir">
                <div class="sticker clickable" @click="cliсkInfoDrawer;" >
                    Кэшбек по карте МИР
                </div>
                <div class="sticker" v-if="tariff.best_price">
                    Лучшая цена
                </div>
            </div>
        </el-card>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    props: {
        tabName: String,
        tariff: Object,
        roomNumberIdx: Number,
        roomId: Number,
        guestAge: Number,
        categoryFilter: String,
        currentCategoryFilter: String
    },
    data() {
        return {
            guestParams: this.$store.state.guestParams
        };
    },
    
    computed: {
        ...mapGetters(["DAYS", "BACKEND_TARIFFS"]),
        calculateOldPrice: function() {
            const oldPrice = this.tariff.fullPrice * 100 / (100 - this.tariff.discount)
            return Math.ceil(oldPrice / 10) * 10
        }       
    },
    methods: {
         isVisible() {
            if (this.categoryFilter == "all") {
                return true;
            }
            if (this.categoryFilter == "treatment") {
                if (this.tariff.treatment == true) {
                    return true;
                }
            }
            if (this.categoryFilter == "rest") {
                if (this.tariff.rest == true) {
                    return true;
                }
            }
            if (this.categoryFilter == "special_offer") {
                if (this.tariff.special_offer == true) {
                    return true;
                }
            }
            return false;

        },
        choiceTariff(tariff) {
            
            this.$emit("choiceTariff", {
                roomId: this.roomId,
                guestAge: this.guestAge,
                tariff: tariff,
            });
        },
        unChoiceTariff(tariff) {
            console.log("UNCHOICE_T", tariff)
            this.$emit("unChoiceTariff", this.tabName);

        },
        clickMoreInfo(tariff, tabName, roomNumberIdx) {
            
            let t = {
                tariff: tariff,
                guestAge: this.guestAge,
                tabName: tabName,
                idx: roomNumberIdx,
                isChoosen: this.isChoosen(tariff.tariffId, tabName, tariff.isRoom)
            }
            this.$emit("clickMoreInfo", t );
            
        },
        clickInfoDrawer() {
            this.$emit("clickInfoDrawer");
        },
        isChoosen(tariffId, tabName, isRoom) {
            for(let idx = 0; idx < this.guestParams.length; idx++) {
                let param = this.guestParams[idx]
                for (let innerIdx = 0; innerIdx < param.adults.tariffIds.length; innerIdx++) {
                    let currentId  = param.adults.tariffIds[innerIdx].id
                    let currentTabName  = param.adults.tariffIds[innerIdx].tabName
                    let currentIsRoom = param.adults.tariffIds[innerIdx].isRoom
                    if (currentId == tariffId && currentTabName == tabName && currentIsRoom == isRoom)
                        return true
                }
                for (let innerIdx2 = 0; innerIdx2 < param.childs.tariffIds.length; innerIdx2++) {
                    let currentId  = param.childs.tariffIds[innerIdx2].id
                    let currentTabName  = param.childs.tariffIds[innerIdx2].tabName
                    let currentIsRoom = param.childs.tariffIds[innerIdx2].isRoom
                    if (currentId == tariffId && currentTabName == tabName && currentIsRoom == isRoom)
                        return true
                }
            }
            return false
        },
        rerenderCard() {
            this.$forceUpdate()
        }
        
    },
};
</script>

<template>
    <div class="step-2">
        <header>
            <div class="top-header">
                <div class="header-back" @click="setStep(1)">
                    <i class="el-icon-arrow-left"></i>
                    <span>Назад к датам</span>
                </div>

                <div class="header-dates" @click="setStep(1)">
                    <i class="las la-calendar-week"></i>
                    <span
                        >{{ formatDate(startDate) }} -
                        {{ formatDate(endDate) }} ({{ nightsInfo }})</span
                    >
                </div>

                <div class="header-room" @click="setStep(1)">
                    <i class="las la-user"></i>
                    <span>{{ bookingInfo }}</span>
                </div>
            </div>

            <el-progress :percentage="25" :format="format()"></el-progress>
        </header>

        <div class="header-featured-image">
            <img :src="bannerURL" alt="featured image" />
        </div>
        <div class="preloader load-data-error" v-if="!isDataReady">
            <div class="error-view">
                <p class="error-info">Ошибка получения данных от сервера</p>
                <p>
                    Пожалуйста, попробуйте снова или обратитесь к менеджеру
                    позвонив по телефону
                    <a href="tel:8-800-707-5182">8 800 707 5182</a>.
                </p>
                <el-button
                    class="error-button"
                    type="primary"
                    @click="setStep(1)"
                >
                    Попробовать заново
                </el-button>
            </div>
            
        </div>

        <div class="message-view" v-if="searchData[0].availableRoom.length == 0 && isDataReady">
            <p class="error-info">Не найдено свободных номеров по вашим критериям</p>
            <p>Пожалуйста, попробуйте снова, выбрав другие условия или обратитесь к менеджеру позвонив по телефону <a href="tel:8-800-707-5182">8 800 707 5182</a>.</p>
            <el-button
                class="error-button"
                type="primary"
                @click="setStep(1)">
                Попробовать заново
            </el-button>
		</div>

        <template v-else>
            <div v-if="searchData.length == guestParams.length && isDataReady" :key="roomIdx">
                <div class="step-heading">
                    <h3>
                        Выберите номер {{ roomIdx + 1 }} в {{ division_name }}
                    </h3>

                    <div class="room-view">
                        <div
                            v-bind:class="[{ active: isGridView }, gridClass]"
                            @click="isGridView = true"
                        >
                            <i class="el-icon-menu"></i>
                        </div>
                        <div
                            :class="[{ active: !isGridView }, listClass]"
                            @click="isGridView = false"
                        >
                            <i class="las la-bars"></i>
                        </div>
                    </div>
                </div>
                <!-- <CoolLightBox
                    v-if="photos.length > 0"
                    :items="photos[currentRoomId]"
                    :index="index"
                    @close="index = null"
                >
                </CoolLightBox> -->
                <div class="grid-view" v-if="isGridView == true">
                    <div
                        class="room"
                        v-for="room in roomsFiltredByIdx(roomIdx)"
                        :key="room.id"
                    >
                        <div
                            class="card"
                            :class="{
                                'active-card':
                                    guestParams[roomIdx].roomId == room.id,
                            }"
                        >
                 
                            <div class="picture" >
                                <el-carousel :autoplay="false" height="230px"  v-if="thumbnails2x[room.id]">
                                    <el-carousel-item
                                        v-for="(image, imageIndex) in thumbnails2x[room.id]"
                                        :key="imageIndex"
                                        class="images-wrapper"
                                    >
                                        <div
                                            class="image"
                                            :style="{
                                                backgroundImage:
                                                    'url(' + image + ')',
                                            }"
                                            @click="index = imageIndex; handlerCarouselClick(room.id, imageIndex)"
                                        ></div>
                                    </el-carousel-item>
                                </el-carousel>
                                <el-carousel :autoplay="false" height="230px" v-else>
                                    <el-carousel-item
                                        v-for="(image, imageIndex) in ['img/placeholder.png']"
                                        :key="imageIndex"
                                        class="images-wrapper"
                                    >
                                        <div
                                            class="image"
                                            :style="{
                                                backgroundImage:
                                                    'url(' + image + ')',
                                            }"
                                            @click="index = imageIndex; handlerCarouselClick(room.id, imageIndex)"
                                        ></div>
                                    </el-carousel-item>
                                </el-carousel>

                                <div class="features">
                                    <div 
                                    v-for="(item, index ) in room.main_equipment"
                                    :key="index"
                                    >
                                        <i class="las" :class="item.icon"></i>
                                       <!-- {{item}} -->
                                    </div> 
                                </div>
                                <div class="stickers">
                                    <div class="sticker" v-if="room.sales_leader">Лидер продаж</div>
                                </div>
                            </div>
                            <div
                                class="description"
                                @click="handlerRoomInfo(room.id)"
                            >
                                <div class="title">
                                    {{ room.title }}
                                </div>
                                <div class="column">
                                    <div class="properties">
                                        <div>
                                            <i
                                                class="las la-ruler-combined"
                                            ></i>
                                            <span>{{ room.area }} м²</span>
                                        </div>
                                        <div>
                                            <i class="las la-user-friends"></i>
                                            <span>до {{ room.seats_number }} мест</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="column">
                                    <div class="properties">
                                        <div></div>
                                        <div class="last-room">
                                            <span v-if="room.isLast"
                                                >Остался 1 номер</span
                                            >
                                        </div>
                                        <div>
                                            <div
                                                class="link"
                                                @click="handlerRoomInfo(room.id)"
                                            >
                                                Описание номера
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="footer">
                                    <div class="column">
                                        <div class="price">
                                            <div class="price-numbers">
                                                <!-- <div class="old-price">
                                                    62 000 ₽
                                                </div> -->
                                                <div class="actual-price">
                                                    от
                                                    {{ room.minPrice }} ₽
                                                </div>
                                            </div>
                                            <!-- <div class="sale">-5%</div> -->
                                        </div>
                                    </div>
                                    <div class="column">
                                        <el-button
                                            class="choose"
                                            type="primary"
                                            @click.stop="choiceRoom(room, roomIdx)"
                                            >Выбрать</el-button
                                        >
                                        <el-button
                                            class="choosen"
                                            type="primary"
                                            @click.stop="choiceRoom(room, roomIdx)"
                                        >
                                            <i class="las la-check"></i>
                                            Далее
                                        </el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="list-view" v-if="isGridView == false">
                    <div
                        class="room"
                        v-for="room in roomsFiltredByIdx(roomIdx)"
                        :key="room.id"
                    >
                        <div
                            class="item"
                            :class="{
                                'active-card':
                                    guestParams[roomIdx].roomId == room.id,
                            }"
                        >
                            <div class="picture" v-if="thumbnails2x[room.id] && thumbnails2x[room.id].length > 0">
                                <img :src=thumbnails2x[room.id][0] alt="" />
                                 <div class="features">
                                    <div 
                                    v-for="(item, index ) in room.main_equipment"
                                    :key="index"
                                    >
                                        <i class="las" :class="item.icon"></i>
                                    </div> 
                                </div>
                            </div>
                            <div class="picture" v-else>
                                <img  src="img/placeholder.png" alt="" />
                                 <div class="features">
                                    <div 
                                    v-for="(item, index ) in room.main_equipment"
                                    :key="index"
                                    >
                                        <i class="las" :class="item.icon"></i>
                                    </div> 
                                </div>
                                
                            </div>
                            <div
                                class="description"
                                @click="handlerRoomInfo(room.id)"
                            >
                                <div class="main column">
                                    <div class="title">
                                        {{ room.title }}
                                    </div>
                                    <div class="properties">
                                        <div class="last-room">
                                            <span v-if="room.isLast"
                                                >Остался 1 номер</span
                                            >
                                        </div>
                                        <div>
                                            <div>
                                                <i
                                                    class="
                                                        las
                                                        la-ruler-combined
                                                    "
                                                ></i>
                                                <span>{{ room.area }} м²</span>
                                            </div>
                                            <div>
                                                <i
                                                    class="las la-user-friends"
                                                ></i>
                                                <span>до {{ room.seats_number }} мест</span>
                                            </div>
                                        </div>

                                        <div>
                                            <div
                                                class="link"
                                                @click="handlerRoomInfo(room.id)"
                                            >
                                                Описание номера
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="footer column">
                                    <div class="price">
                                        <div class="price-numbers">
                                           
                                            <div class="actual-price">
                                                от {{ room.minPrice }} ₽
                                            </div>
                                        </div>
                                        <!-- <div class="sale">-5%</div> -->
                                    </div>

                                    <el-button
                                        class="choose"
                                        type="primary"
                                        @click.stop="choiceRoom(room, roomIdx)"
                                        >Выбрать</el-button
                                    >
                                    <el-button
                                        class="choosen"
                                        type="primary"
                                        @click.stop="choiceRoom(room, roomIdx)"
                                    >
                                        <i class="las la-check"></i>
                                        Далее
                                    </el-button>
                                </div>

                                <div class="stickers">
                                    <div class="sticker" v-if="room.sales_leader">Лидер продаж</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <!-- <RoomMoreInfoDrawer
            :expand="isOpenRoomMoreInfo"
            :roomInfo="roomInfo"
            :guestParams="guestParams"
            @collapseRoomMoreInfo="isOpenRoomMoreInfo = false"
            @choiceRoom="choiceRoom(currentRoom, roomIdx)"
            @unChoiceRoom="unChoiceRoom(roomIdx)"
            @clickPhoto="handlerCarouselClick(roomInfo.idZdravnitsa, $event)"
        /> -->

        <div class="footer-info" v-if="isDataReady && searchData[0].availableRoom.length > 0">
            Не нашли интересующий вас вариант? Позвоните нам по телефону
            <a href="tel:8-800-707-5182">8 800 707 5182</a>, мы поможем.
        </div>
    </div>
</template>

<script>
// import RoomMoreInfoDrawer from "./RoomMoreInfoDrawer.vue";
import { mapActions, mapState } from "vuex";
import { formatProgressBar } from "../api.js";
import axios from "axios";

export default {
    // components: {
    //     RoomMoreInfoDrawer,
    // },
    props: {
        fillPhotos: Function,
        fillRooms: Function,
        handlerRoomInfo: Function,
        choiceRoom: Function,
        photos: Object,
        thumbnails: Object,
        thumbnails2x: Object,
        rooms: Array,
        roomIdx:Number
    },
    data() {
        return {
            isOpenRoomMoreInfo: false,
            //roomIdx: 0,
            // rooms: [],
            isGridView: true,
            listClass: "list",
            gridClass: "grid",
            activeCardClass: "active-card",
            // photos:{},
            // thumbnails:{},
            // thumbnails2x:{},
            roomInfo: {},
            // currentRoom: null,
            currentRoomId: null,
            index: null,
            isDataReady: true,
        };
    },
    mounted: async function () {
        await this.getBackendRooms();
        this.fillRooms();
        this.getBackendTariffs();
        this.fillPrices();
        this.fillPhotos();
        
    },
    // beforeUpdate: function () {
    //     this.getDrawerMaxHeight()
    // },
    computed: {
        ...mapState([
            'bookingInfo',
            'nightsInfo',
            'searchData',
            'startDate',
            'endDate',
            'guestParams',
            'backendRooms',
            'backApiUrl',
            'settings'
        ]),
        bannerURL() {
            return this.$store.state.settings[0].banner;
        },
        division_name() {
            let division =  this.$store.state.division
            if (division === 'e66f7d7a-a8fd-4aea-a5b5-b6ace867b7c2') {
                return 'санатории "Катунь"'
            }
            if (division === 'dcb6fbcb-7009-47ff-baad-c0027817ddbc') {
                return 'санатории "Сибирь"'
            }
            if (division === '70967e77-a573-4294-a69f-17648bd1b003') {
                return 'санатории "Белокуриха"'
            }
            return ''
            
        }

    },
    methods: {
        ...mapActions([
            "SET_STEP",
            "SET_ROOMS",
            "SET_BACKEND_ROOMS",
            "SET_BACKEND_TARIFFS",
            "SET_GUEST_PARAMS",
            "SET_PRICES",
        ]),
        setStep(stepNumber) {
            this.SET_STEP(stepNumber);
        },
        formatDate(inputDate) {
            const date = new Date(inputDate);
            const month = date.getMonth();
            const day = date.getDate();

            if (month === 0) {
                return `${day} января`;
            } else if (month === 1) {
                return `${day} февраля`;
            } else if (month === 2) {
                return `${day} марта`;
            } else if (month === 3) {
                return `${day} апреля`;
            } else if (month === 4) {
                return `${day} мая`;
            } else if (month === 5) {
                return `${day} июня`;
            } else if (month === 6) {
                return `${day} июля`;
            } else if (month === 7) {
                return `${day} августа`;
            } else if (month === 8) {
                return `${day} сентября`;
            } else if (month === 9) {
                return `${day} октября`;
            } else if (month === 10) {
                return `${day} ноября`;
            } else {
                return `${day} декабря`;
            }
        },
        format() {
            return formatProgressBar;
        },

        // getRoomInfoById(id) {
        //     const bkRooms = this.backendRooms;
        //     const roomInfo = {
        //         title: "",
        //         description: "",
        //         numberOfSeats: null,
        //         area: null,
        //         photos: this.photos[id],
        //         minPrice: null,
        //         isLast: null,
        //         id: null,
        //         idx: null,
        //         main_equipment: [],
        //         equipment: [],
        //         salesLeader: null
        //     };
        //     if (bkRooms) {
        //         for (let idx = 0; idx < bkRooms.length; idx++) {
        //             if (bkRooms[idx].id_zdravnitsa == id) {
        //                 roomInfo.description = bkRooms[idx].description;
        //                 roomInfo.numberOfSeats = bkRooms[idx].seats_number;
        //                 roomInfo.area = bkRooms[idx].area;
        //                 roomInfo.title = bkRooms[idx].title;
        //                 roomInfo.main_equipment = bkRooms[idx].main_equipment
        //                 roomInfo.equipment = bkRooms[idx].equipment
        //                 roomInfo.salesLeader = bkRooms[idx].sales_leader
        //                 roomInfo.idZdravnitsa = bkRooms[idx].id_zdravnitsa
        //             }
        //         }
        //     }
        //     return roomInfo;
        // },
        async getBackendRooms() {
            const url = `${this.backApiUrl}/rooms/`;
            await axios
                .get(url)
                .then((resp) => {
                    this.SET_BACKEND_ROOMS(resp.data);
                    return resp.data;
                })
                .catch((err) => {
                    console.log("ERROR_MESSAGE", err);
                    this.isDataReady = false;
                });
        },
        async getBackendTariffs() {
            const url = `${this.backApiUrl}/tariffs/`;
            const resp = await axios(url, {
                method: "GET",
            });
            if (resp.data) {
                this.SET_BACKEND_TARIFFS(resp.data);
            }
        },
        // async choiceRoom(room, roomIdx) {
        //     this.guestParams[roomIdx].roomId = room.id;
        //     this.guestParams[roomIdx].descZdrav = room.descZdrav;
        //     await this.SET_GUEST_PARAMS(this.guestParams);
        //     this.showMessage(
        //         `${1 + roomIdx}-й номер успешно выбран`,
        //         "success"
        //     );
        //     if (this.roomIdx === this.guestParams.length - 1) {
        //         this.setStep(3);
        //     } else {
        //         this.roomIdx++;
        //     }
        // },
        // async unChoiceRoom(roomIdx) {
        //     this.guestParams[roomIdx].roomId = null;
        //     this.guestParams[roomIdx].descZdrav = null;
        //     await this.SET_GUEST_PARAMS(this.guestParams);
        // },
        minTariffPrice(room) {
            let tariffs = room.guests[0].tariffs;
            let minTariff = tariffs[0].fullPrice;
            for (let idx = 0; idx < tariffs.length; idx++) {
                if (tariffs[idx].fullPrice < minTariff) {
                    minTariff = tariffs[idx].fullPrice;
                }
            }
            return minTariff;
        },
        // async handlerRoomInfo(room) {
        //     // this.getScrollTopDrawer()
        //     this.$emit('scrollToTop')
        //     this.roomInfo = this.getRoomInfoById(room.id);
        //     this.roomInfo.minPrice = room.minPrice;
        //     this.roomInfo.isLast = room.isLast;
        //     this.roomInfo.id = room.id;
        //     this.roomInfo.idx = this.roomIdx;
        //     this.currentRoom = room;
        //     this.isOpenRoomMoreInfo = true; 
            
        // },
        consoleLog(msg, variable) {
            console.log(msg, variable);
        },
        // async fillRooms() {
        //     let tmpRooms = [];

        //     for (let idx = 0; idx < this.searchData.length; idx++) {
        //         let availableRoom = this.searchData[idx].availableRoom;
        //         for (
        //             let innerIdx = 0;
        //             innerIdx < availableRoom.length;
        //             innerIdx++
        //         ) {
        //             let currentRoom = availableRoom[innerIdx];
        //             let room = {
        //                 idx: idx,
        //                 id: currentRoom.id,
        //                 isLast: currentRoom.room == 1 ? true : false,
        //                 title: "",
        //                 description: "",
        //                 descZdrav: currentRoom.description,
        //                 area: "",
        //                 seats_number: "",
        //                 update_room1: "",
        //                 update_room2: "",
        //                 minPrice: this.minTariffPrice(currentRoom),
        //                 main_equipment: [],
        //                 equipment: [],
        //                 sales_leader: null
        //             };
        //             tmpRooms.push(room);
        //         }
        //     }
        //     for (let idx = 0; idx < tmpRooms.length; idx++) {
        //         let currentRoom = tmpRooms[idx];
        //         let currentBackendRoom = this.backendRooms.filter((item) => {
        //             return item.id_zdravnitsa === currentRoom.id;
        //         });
               
        //         if (currentBackendRoom.length > 0) {
        //             currentRoom.title = currentBackendRoom[0].title;
        //             currentRoom.description = currentBackendRoom[0].description;
        //             currentRoom.area = currentBackendRoom[0].area;
        //             currentRoom.seats_number =
        //                 currentBackendRoom[0].seats_number;
        //             currentRoom.update_room1 =
        //                 currentBackendRoom[0].update_room1;
        //             currentRoom.update_room2 =
        //                 currentBackendRoom[0].update_room2;
        //             currentRoom.main_equipment = currentBackendRoom[0].main_equipment
        //             currentRoom.equipment = currentBackendRoom[0].equipment
        //             currentRoom.sales_leader = currentBackendRoom[0].sales_leader
        //         }
        //     }
        //     this.rooms = tmpRooms;
        //     this.SET_ROOMS(tmpRooms);
        // },
        fillPrices() {
            let pricesTable = [];
            for (let idx = 0; idx < this.searchData.length; idx++) {
                let availableRooms = this.searchData[idx].availableRoom;
                for (let idx2 = 0; idx2 < availableRooms.length; idx2++) {
                    let room = availableRooms[idx2];
                    for (let idx3 = 0; idx3 < room.guests.length; idx3++) {
                        let guest = room.guests[idx3];
                        let tariffs = guest.tariffs;
                        for (let idx4 = 0; idx4 < tariffs.length; idx4++) {
                            let tariff = tariffs[idx4];
                            let tableItem = {
                                roomNumber: idx,
                                roomId: room.id,
                                age: guest.age,
                                tariffId: tariff.tariffId,
                                price: tariff.fullPrice,
                                isRoom: tariff.isRoom,
                            };
                            pricesTable.push(tableItem);
                        }
                    }
                }
            }
            this.SET_PRICES(pricesTable);
        },
        roomsFiltredByIdx(idx) {
            let filtredRooms = this.rooms.filter((item) => {
                return item.idx == idx;
            });
            return filtredRooms;
            
        },
        showMessage(msg, msgType) {
            this.$message({
                message: msg,
                type: msgType,
                duration: 4000,
            });
        },
        // async getRoomPhoto(roomId) {
        //     const url = `${this.backApiUrl}/photos/`;
            
        //     const resp = await axios(url, {
        //         method: "GET",
        //         params: {room_id: roomId}
        //     });
        //     if (resp.data) {
        //         return resp.data
        //     }
        //     return null
        // },
        // getBackendRoomByZdravId(id) {
        //     const rooms = this.backendRooms
        //     for(let idx = 0; idx < rooms.length; idx++) {
        //         if (rooms[idx].id_zdravnitsa == id) {
        //             return rooms[idx]
        //         }
        //     }
        //     return null

        // }, 
        // async fillPhotos() {
        //     let photos = {}
        //     let thumbnails = {}
        //     let thumbnails2x = {}
        //     let roomIds = []
        //     for (let idx = 0; idx < this.searchData.length; idx++) {
        //         let availableRooms = this.searchData[idx].availableRoom;
        //         for (let idx2 = 0; idx2 < availableRooms.length; idx2++) {
        //             let roomId = availableRooms[idx2].id
        //             if (roomIds.indexOf(roomId) === -1){
        //                 roomIds.push(roomId)
        //             }
        //         }
        //     }
        //     for (let idx = 0; idx < roomIds.length; idx++) {
        //         let tmpPhotos = []
        //         let tmpThumbnails = []
        //         let tmpThumbnails2x = []
        //         let backendRoom = await this.getBackendRoomByZdravId(roomIds[idx])
        //         if (backendRoom) {
                    
        //             let currentPhotos = await this.getRoomPhoto(backendRoom.id)
        //             for (let idx2 = 0; idx2 < currentPhotos.length; idx2++) {
        //                 tmpPhotos.push(currentPhotos[idx2].image)
        //                 tmpThumbnails.push(currentPhotos[idx2].thumbnail)
        //                 tmpThumbnails2x.push(currentPhotos[idx2].thumbnail_2x)
        //             }
        //         photos[roomIds[idx]] = tmpPhotos
        //         thumbnails[roomIds[idx]] = tmpThumbnails
        //         thumbnails2x[roomIds[idx]] = tmpThumbnails2x
        //         }
                
        //     }
           
        //     this.photos = photos
        //     this.thumbnails = thumbnails
        //     this.thumbnails2x = thumbnails2x
            
        // },
        handlerCarouselClick(roomId, imageIndex) {
            this.currentRoomId = roomId
            const SITE_URL = this.$store.state.parentSiteUrl
            parent.postMessage({roomPhotos: this.photos[roomId], imageIndex: imageIndex}, SITE_URL);
        },
    },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-form',{ref:"contactForm",staticClass:"demo-dynamic",attrs:{"model":_vm.contactForm}},[_c('div',{staticClass:"form-fields-group"},[_c('h2',{attrs:{"id":"formFirstLine"}},[_vm._v("Укажите ФИО и контакты плательщика")]),_c('div',{staticClass:"form-line fio-line"},[_c('el-form-item',{attrs:{"prop":"secondName"}},[_c('el-input',{class:{
                        'el-form-error':
                            _vm.$v.contactForm.payerSecondName.$error,
                    },attrs:{"placeholder":"Фамилия"},on:{"blur":function($event){return _vm.$v.contactForm.payerSecondName.$touch()}},model:{value:(_vm.contactForm.payerSecondName),callback:function ($$v) {_vm.$set(_vm.contactForm, "payerSecondName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"contactForm.payerSecondName"}}),(_vm.$v.contactForm.payerSecondName.$error)?_c('div',[(!_vm.$v.contactForm.payerSecondName.required)?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" Поле должно быть заполнено ")]):_vm._e(),(!_vm.$v.contactForm.payerSecondName.minLength)?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" Поле должно содержать больше одного символа ")]):_vm._e()]):_vm._e()],1),_c('el-form-item',{attrs:{"prop":"name"}},[_c('el-input',{class:{
                        'el-form-error': _vm.$v.contactForm.payerName.$error,
                    },attrs:{"placeholder":"Имя"},model:{value:(_vm.contactForm.payerName),callback:function ($$v) {_vm.$set(_vm.contactForm, "payerName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"contactForm.payerName"}}),(_vm.$v.contactForm.payerName.$error)?_c('div',[(!_vm.$v.contactForm.payerName.required)?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" Поле должно быть заполнено ")]):_vm._e(),(!_vm.$v.contactForm.payerName.minLength)?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" Поле должно содержать больше одного символа ")]):_vm._e()]):_vm._e()],1),_c('el-form-item',{attrs:{"prop":"thirdName"}},[_c('el-input',{attrs:{"placeholder":"Отчество"},model:{value:(_vm.contactForm.payerThirdName),callback:function ($$v) {_vm.$set(_vm.contactForm, "payerThirdName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"contactForm.payerThirdName"}})],1)],1),_c('div',{staticClass:"form-line with-icon"},[_c('div',{staticClass:"field"},[_c('i',{staticClass:"las la-envelope"}),_c('el-form-item',{attrs:{"prop":"email"}},[_c('el-input',{class:{
                            'el-form-error': _vm.$v.contactForm.email.$error,
                        },attrs:{"placeholder":"Email"},on:{"blur":function($event){return _vm.$v.contactForm.email.$touch()}},model:{value:(_vm.contactForm.email),callback:function ($$v) {_vm.$set(_vm.contactForm, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"contactForm.email"}}),(_vm.$v.contactForm.email.$error)?_c('div',[(!_vm.$v.contactForm.email.required)?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" Поле должно быть заполнено ")]):_vm._e(),(!_vm.$v.contactForm.email.email)?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" Введите корректный Email ")]):_vm._e()]):_vm._e()],1)],1),_c('div',{staticClass:"label-block"},[_vm._v(" Мы вышлем вам подтверждение бронирования на этот адрес ")])]),_c('div',{staticClass:"form-line with-icon"},[_c('div',{staticClass:"field"},[_c('i',{staticClass:"las la-phone"}),_c('el-form-item',{attrs:{"prop":"phone"}},[_c('el-input',{class:{
                            'el-form-error': _vm.$v.contactForm.phone.$error,
                        },attrs:{"placeholder":"Телефон","type":"tel","clearable":""},on:{"input":function($event){return _vm.inputPhone($event)},"clear":_vm.clearPhone,"blur":function($event){return _vm.$v.contactForm.phone.$touch()}},model:{value:(_vm.contactForm.phone),callback:function ($$v) {_vm.$set(_vm.contactForm, "phone", $$v)},expression:"contactForm.phone"}}),(_vm.$v.contactForm.phone.$error)?_c('div',[(!_vm.$v.contactForm.phone.validFormat)?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" Укажите корректный номер телефона ")]):_vm._e()]):_vm._e()],1)],1),_c('div',{staticClass:"label-block"},[_vm._v(" Телефон нужен для оперативного решения вопросов по бронированию ")])]),_c('div',{staticClass:"form-line checkbox-line"},[_c('el-checkbox',{model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_vm._v("Я хочу узнавать о специальных предложения по email или SMS")])],1)]),_c('div',{staticClass:"form-fields-group"},[_c('h2',[_vm._v("Введите данные гостей")]),_vm._l((_vm.GUEST_PARAMS),function(item,idx){return _c('div',{key:idx,staticClass:"form-field-group"},[_c('h3',{staticClass:"head-title form-title"},[_vm._v("Номер "+_vm._s(idx + 1))]),_vm._l((_vm.GUESTS[idx]),function(i,idx2){return _c('div',{key:idx2},[_c('div',{staticClass:"form-title"},[_c('div',[(i <= item.adults.quantity)?_c('div',[_vm._v(" Гость "+_vm._s(i)+": Взрослый на "),(!item.adults.isDop[idx2])?_c('span',[_vm._v(" основном ")]):_c('span',[_vm._v("дополнительном")]),_vm._v(" месте "),_c('div',[_vm._v(" Путевка: "+_vm._s(_vm.getTariffTitle( item.adults.tariffIds[idx2].id ))+" ")])]):_c('div',[_vm._v(" Ребенок на "),(
                                    !item.childs.isDop[
                                        idx2 - item.adults.quantity
                                    ]
                                )?_c('span',[_vm._v(" основном ")]):_c('span',[_vm._v("дополнительном")]),_vm._v(" месте ")])])]),_c('FioLine',{attrs:{"pSecondName":_vm.contactForm.payerSecondName,"pName":_vm.contactForm.payerName,"pThirdName":_vm.contactForm.payerThirdName,"isAdult":i <= item.adults.quantity,"roomIdx":idx,"guestIdx":idx2,"secondName":_vm.contactForm.fio[idx][idx2].secondName,"name":_vm.contactForm.fio[idx][idx2].name,"thirdName":_vm.contactForm.fio[idx][idx2].thirdName},on:{"input":function($event){return _vm.fioInput($event)}}})],1)}),_c('RoomLevelUp',{attrs:{"roomId":_vm.GUEST_PARAMS[idx].roomId,"roomNumber":idx,"handlerRoomInfo":_vm.handlerRoomInfo,"scrollToTop":_vm.scrollToTop},on:{"syncRoom":function($event){return _vm.syncRoomsWithOrder()}}})],2)}),_c('div',{staticClass:"form-field-group"},[_c('div',{staticClass:"form-title"},[_c('div',[_vm._v("Комментарии к заказу")])]),_c('div',{staticClass:"form-line"},[_c('el-form-item',{staticClass:"textarea-item",attrs:{"prop":"comment"}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 3, maxRows: 8 },"placeholder":"Если у вас есть пожелания, укажите их здесь"},model:{value:(_vm.contactForm.comment),callback:function ($$v) {_vm.$set(_vm.contactForm, "comment", $$v)},expression:"contactForm.comment"}})],1)],1),_c('div',{staticClass:"form-line promocode-line"},[_c('el-form-item',{attrs:{"prop":"promocode"}},[_c('el-input',{attrs:{"placeholder":"Введите промокод"},model:{value:(_vm.contactForm.promocode),callback:function ($$v) {_vm.$set(_vm.contactForm, "promocode", $$v)},expression:"contactForm.promocode"}})],1),_c('div',{staticClass:"label-block"},[_vm._v(" Есть промокод? Укажите его в этом поле ")])],1)])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div id="app">
        <template v-if="currentStep === 1">
            <StepOne ref="stepOne" />
        </template>
        <template v-if="currentStep === 2">
            <StepTwo 
            :fillPhotos="fillPhotos"
            :fillRooms="fillRooms"
            :handlerRoomInfo="handlerRoomInfo"
            :choiceRoom="choiceRoom"
            :rooms="rooms"
            :photos="photos"
            :thumbnails="thumbnails" 
            :thumbnails2x="thumbnails2x"
            :roomIdx="roomIdx"/>
        </template>
        <template v-if="currentStep === 3">
            <StepThree @scrollToTop="scrollToTop" />
        </template>
        <template v-if="currentStep === 4">
            <StepFour
                :handlerRoomInfo="handlerRoomInfo"
                :scrollToTop="scrollToTop"
                @setSession="setSession()"
                @returnToStart="returnToStart(messageText)"
                
            />
        </template>
        <InfoDrawer
            :expand="isInfoDrawer"
            @collapseInfoDrawer="isInfoDrawer = false"
        />
        <RoomMoreInfoDrawer
            :expand="isOpenRoomMoreInfo"
            :roomInfo="roomInfo"
            :guestParams="guestParams"
            :scrollToTop="scrollToTop"
            @collapseRoomMoreInfo="isOpenRoomMoreInfo = false"
            @choiceRoom="choiceRoom(currentRoom, roomIdx)"
            @clickPhoto="handlerCarouselClick(roomInfo.id, $event)"
            @syncOrder="syncOrder()"
        />

        <footer>
            Модуль онлайн-бронирования
            <a href="https://citportal.ru/booking" target="_blank">
                CIT PORTAL
            </a>
        </footer>
    </div>
</template>

<script>
import StepOne from "./components/StepOne.vue";
import StepTwo from "./components/StepTwo.vue";
import StepThree from "./components/StepThree.vue";
import StepFour from "./components/StepFour.vue";
import InfoDrawer from "./components/InfoDrawer.vue";
import RoomMoreInfoDrawer from "./components/RoomMoreInfoDrawer.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import axios from "axios";
import store from "./store";
export default {
    name: "App",
    components: {
        StepOne,
        StepTwo,
        StepThree,
        StepFour,
        InfoDrawer,
        RoomMoreInfoDrawer,
    },
    data() {
        return {
            roomExpandIdx: -1,
            isMoreInfo: false,
            isInfoDrawer: false,
            moreInfoData: "",
            tariffs: this.$store.state.searchData.availableTariff,
            roomsData: this.$store.state.searchData.availableRoom,
            // roomsInfo: [],
            messageText:
                "Пока вы не пользовались модулем, возможно изменилась доступность номеров. Пожалуйста начните подбор номера заново.",
            isOpenRoomMoreInfo: false,
            rooms: [],
            roomInfo: { idx: 0 },
            photos: {},
            thumbnails: {},
            thumbnails2x: {},
            currentRoom: null,
            roomIdx: 0,
        };
    },
    mounted: function () {
        const PAGE_CONTENT = "app"; 
        const SITE_URL = this.$store.state.parentSiteUrl;

        document.addEventListener("DOMContentLoaded", function () {
            // Вторым параметром в postMessage можно передать '*' - тогда сообщение будет доступно для всех окон.
            parent.postMessage(
                document.getElementById(PAGE_CONTENT).clientHeight,
                SITE_URL
            );
        });

        window.addEventListener("DOMSubtreeModified", function () {
            parent.postMessage(
                document.getElementById(PAGE_CONTENT).clientHeight,
                SITE_URL
            );
            
        });
        //для приема сообщений от родительской страницы
        window.addEventListener("message", this.receiveMessage);
    },
    computed: {
        ...mapGetters([
            "DAYS",
            "START_DATE",
            "END_DATE",
            "ROOMS_STATE",
            "TARIFF_STATE",
            "SESSION_URL",
            "PARENT_SITE_URL",
        ]),
        ...mapState([
            "step", 
            "searchData", 
            "backendRooms", 
            "backApiUrl",
            "guestParams"
        ]),

        nightInfo: function () {
            const nightQuantity = this.DAYS_STATE;
            let nightTitle = "";
            if (nightQuantity === 1) {
                nightTitle = "ночь";
            } else if (nightQuantity < 5) {
                nightTitle = "ночи";
            } else {
                nightTitle = "ночей";
            }
            return `${nightQuantity} ${nightTitle}`;
        },

        currentStep: function () {
            return this.step;
        },
    },
    methods: {
        ...mapActions([
            "SEARCH",
            "GET_CONFIG",
            "SET_SESSION",
            "SET_STEP",
            "SET_ADDED_ROOMS",
            "SET_SCROLL_TOP_DRAWER",
            "SET_DRAWER_MAX_HEIGHT",
        ]),
        async startSearch() {},

        expandOrCollapseDesc(idx) {
            if (this.roomExpandIdx == idx) {
                this.roomExpandIdx = -1;
            } else {
                this.roomExpandIdx = idx;
            }
        },
        clickMoreInfo(info) {
            this.isMoreInfo = true;
            this.moreInfoData = info;
        },
        clickInfoDrawer() {
            this.isInfoDrawer = true;
        },

        async getSpkSession() {
            const resp = await axios(this.SESSION_URL, {
                method: "GET",
                headers: { "X-API-Key": this.$store.state.apiKey },
            });
            return resp.data.data;
        },
        async setSession() {
            const spkSession = await this.getSpkSession();
            this.SET_SESSION(spkSession);
        },
        returnToStart(message) {
            this.$alert(message, "", {
                confirmButtonText: "Начать заново",
                callback: () => {
                    this.SET_STEP(1);
                    this.SET_ADDED_ROOMS(null);
                },
            });
        },
        async removeAllRooms() {
            // let clasterIds = []
        },
        receiveMessage(event) {
            if (event.origin != this.$store.state.parentSiteUrl) {
                // что-то прислали с неизвестного домена - проигнорируем..
                
            }
            
            if (event.data.url) {
                this.$refs.stepOne.handleURLParams(event.data.url);
            }
        },
        scrollToTop() {
            const SITE_URL = this.$store.state.parentSiteUrl;
            parent.postMessage({ scrollToTop: "" }, SITE_URL);
        },
        minTariffPrice(room) {
            let tariffs = room.guests[0].tariffs;
            let minTariff = tariffs[0].fullPrice;
            for (let idx = 0; idx < tariffs.length; idx++) {
                if (tariffs[idx].fullPrice < minTariff) {
                    minTariff = tariffs[idx].fullPrice;
                }
            }
            return minTariff;
        },
        async fillRooms() {
            let tmpRooms = [];

            for (let idx = 0; idx < this.searchData.length; idx++) {
                let availableRoom = this.searchData[idx].availableRoom;
                for (
                    let innerIdx = 0;
                    innerIdx < availableRoom.length;
                    innerIdx++
                ) {
                    let currentRoom = availableRoom[innerIdx];
                    let room = {
                        idx: idx,
                        id: currentRoom.id,
                        isLast: currentRoom.room == 1 ? true : false,
                        title: "",
                        description: "",
                        descZdrav: currentRoom.description,
                        area: "",
                        seats_number: "",
                        update_room1: "",
                        update_room2: "",
                        minPrice: this.minTariffPrice(currentRoom),
                        main_equipment: [],
                        equipment: [],
                        sales_leader: null,
                    };
                    tmpRooms.push(room);
                }
            }
            for (let idx = 0; idx < tmpRooms.length; idx++) {
                let currentRoom = tmpRooms[idx];
                let currentBackendRoom = this.backendRooms.filter((item) => {
                    return item.id_zdravnitsa === currentRoom.id;
                });

                if (currentBackendRoom.length > 0) {
                    currentRoom.title = currentBackendRoom[0].title;
                    currentRoom.description = currentBackendRoom[0].description;
                    currentRoom.area = currentBackendRoom[0].area;
                    currentRoom.seats_number =
                        currentBackendRoom[0].seats_number;
                    currentRoom.update_room1 =
                        currentBackendRoom[0].update_room1;
                    currentRoom.update_room2 =
                        currentBackendRoom[0].update_room2;
                    currentRoom.main_equipment =
                        currentBackendRoom[0].main_equipment;
                    currentRoom.equipment = currentBackendRoom[0].equipment;
                    currentRoom.sales_leader =
                        currentBackendRoom[0].sales_leader;
                }
            }
            this.rooms = tmpRooms;
            store.commit("SET_ROOMS", tmpRooms);
        },
        async fillPhotos() {
            let photos = {};
            let thumbnails = {};
            let thumbnails2x = {};
            let roomIds = [];
            for (let idx = 0; idx < this.searchData.length; idx++) {
                let availableRooms = this.searchData[idx].availableRoom;
                for (let idx2 = 0; idx2 < availableRooms.length; idx2++) {
                    let roomId = availableRooms[idx2].id;
                    if (roomIds.indexOf(roomId) === -1) {
                        roomIds.push(roomId);
                    }
                }
            }
            for (let idx = 0; idx < roomIds.length; idx++) {
                let tmpPhotos = [];
                let tmpThumbnails = [];
                let tmpThumbnails2x = [];
                let backendRoom = await this.getBackendRoomByZdravId(
                    roomIds[idx]
                );
                if (backendRoom) {
                    let currentPhotos = await this.getRoomPhoto(backendRoom.id);
                    for (let idx2 = 0; idx2 < currentPhotos.length; idx2++) {
                        tmpPhotos.push(currentPhotos[idx2].image);
                        tmpThumbnails.push(currentPhotos[idx2].thumbnail);
                        tmpThumbnails2x.push(currentPhotos[idx2].thumbnail_2x);
                    }
                    photos[roomIds[idx]] = tmpPhotos;
                    thumbnails[roomIds[idx]] = tmpThumbnails;
                    thumbnails2x[roomIds[idx]] = tmpThumbnails2x;
                }
            }

            this.photos = photos;
            this.thumbnails = thumbnails;
            this.thumbnails2x = thumbnails2x;
        },
        getBackendRoomByZdravId(id) {
            const rooms = this.backendRooms;
            for (let idx = 0; idx < rooms.length; idx++) {
                if (rooms[idx].id_zdravnitsa == id) {
                    return rooms[idx];
                }
            }
            return null;
        },
        async getRoomPhoto(roomId) {
            const url = `${this.backApiUrl}/photos/`;

            const resp = await axios(url, {
                method: "GET",
                params: { room_id: roomId },
            });
            if (resp.data) {
                return resp.data;
            }
            return null;
        },
        getRoomInfoById(id) {
            const bkRooms = this.backendRooms;
            const roomInfo = {
                title: "",
                description: "",
                numberOfSeats: null,
                area: null,
                photos: this.photos[id],
                minPrice: null,
                isLast: null,
                id: null,
                idx: null,
                main_equipment: [],
                equipment: [],
                salesLeader: null
            };
            if (bkRooms) {
                for (let idx = 0; idx < bkRooms.length; idx++) {
                    if (bkRooms[idx].id_zdravnitsa == id) {
                        roomInfo.description = bkRooms[idx].description;
                        roomInfo.numberOfSeats = bkRooms[idx].seats_number;
                        roomInfo.area = bkRooms[idx].area;
                        roomInfo.title = bkRooms[idx].title;
                        roomInfo.main_equipment = bkRooms[idx].main_equipment
                        roomInfo.equipment = bkRooms[idx].equipment
                        roomInfo.salesLeader = bkRooms[idx].sales_leader
                    }
                }
            }
            return roomInfo;
        },
        async handlerRoomInfo(roomId) {
            let room = this.rooms.filter((item) => {
                    return item.id === roomId;
                });
            this.scrollToTop()
            this.roomInfo = this.getRoomInfoById(roomId);
            this.roomInfo.minPrice = room[0].minPrice;
            this.roomInfo.isLast = room[0].isLast;
            this.roomInfo.id = roomId;
            this.roomInfo.idx = this.roomIdx;
            // this.roomsInfo.idx = 0
            this.currentRoom = room[0]; //проверить нужна ли теперь эта переменная
            this.isOpenRoomMoreInfo = true; 
            
        },
        async choiceRoom(room, roomIdx) {
            let tmpGuestParams = this.guestParams
            tmpGuestParams[roomIdx].roomId = room.id;
            tmpGuestParams[roomIdx].descZdrav = room.descZdrav;
            await store.commit("SET_GUEST_PARAMS",tmpGuestParams);
            if (this.step != 4) {
                this.showMessage(
                `${1 + roomIdx}-й номер успешно выбран`,
                "success"
            );
            }
            if (this.step === 2) {
                if (this.roomIdx === tmpGuestParams.length - 1) {
                    store.commit("SET_STEP", 3);
                    this.roomIdx = 0
                } else {
                    this.roomIdx++;
                }
            }
            this.isOpenRoomMoreInfo = false
        },
        showMessage(msg, msgType) {
            this.$message({
                message: msg,
                type: msgType,
                duration: 4000,
            });
        },
        syncOrder() {
            this.$refs.formRef.syncRoomsWithOrder()
            // this.$refs.F
        },
        
    },
};
</script>

<style lang="css">
/* .el-drawer__wrapper {
	top: var(--scrollTopDrawer);
} */

/* .fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
} */
</style>
<template>
  <el-select
  :value="selectedValue"
  placeholder="Добавить ребёнка"
  @change="selectChanged($event)"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
  export default {
    props: {
      selectedValue: Number
    },
    data() {
      return {
        options: [{
          value: 4,
          label: 'Ребёнок 4 года'
        }, {
          value: 5,
          label: 'Ребёнок 5 лет'
        }, {
          value: 6,
          label: 'Ребёнок 6 лет'
        }, {
          value: 7,
          label: 'Ребёнок 7 лет'
        }, {
          value: 8,
          label: 'Ребёнок 8 лет'
        }, {
          value: 9,
          label: 'Ребёнок 9 лет'
        }, {
          value: 10,
          label: 'Ребёнок 10 лет'
        }, {
          value: 11,
          label: 'Ребёнок 11 лет'
        }, {
          value: 12,
          label: 'Ребёнок 12 лет'
        }, {
          value: 13,
          label: 'Ребёнок 13 лет'
        }]
      }
    },
    methods: {
      selectChanged(value) {
        this.$emit('selectChanged', value)
      }
    }
  }
</script>

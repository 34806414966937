import axios from 'axios'
import store from './store'


const addressBookId = '89451090'
const addEmailUrl = 'https://api.sendpulse.com/addressbooks/' + addressBookId + '/emails'
const secret = 'cf7afedc9d8f6b1391de896f5044072f'
const clientId = "cea3a5bd9b81f6f925730060c4362861"
const acessTokenUrl = "https://api.sendpulse.com/oauth/access_token"

function isTokenActual() {
    if (store.state.sendpulseToken) {
        let timestamp = new Date().getTime()
        if (timestamp - store.state.tokenTime < 3600) {
            return true
        }
    }
    return false

}
async function getAccessToken() {
    const data = {
        "grant_type": "client_credentials",
        "client_id": clientId,
        "client_secret": secret
    }
    const resp = await axios.post(acessTokenUrl, data)
    if (resp.data.access_token) {
        let token = resp.data.access_token
        let timestamp = new Date().getTime()
        store.dispatch('SET_SENDPULSE_TOKEN', token)
        store.dispatch('SET_TOKEN_TIME', timestamp)
        return
    }
    console.log("Ошибка при получении токена", resp.data.error);

}

const sendPulseFunctions = {
    
    addEmail: async function addEmail(contactsData) {
        if (!isTokenActual()) {
            await getAccessToken()
        }

        const data = contactsData
        const headers = { Authorization: `Bearer ${store.state.sendpulseToken}` }
        const resp = await axios.post(addEmailUrl, data, {
            headers: headers
        });
        if (resp.data.result) {
            console.log("Успешная отправка email", resp.data);
            return
        }
        console.log("Ошибка при отправке email", resp.data);
    }

}

// export const getAccessToken = sendPulseFunctions.getAccessToken
export const addEmail = sendPulseFunctions.addEmail


<template>
    <div class="step-4">
        <header>
            <div class="top-header">
                <div class="header-back" @click="goToStep3()">
                    <i class="el-icon-arrow-left"></i>
                    <span>Назад к путевкам</span>
                </div>

                <div class="header-dates" @click="SET_STEP(1)">
                    <i class="las la-calendar-week"></i>
                    <span
                        >{{ formatedDate(this.START_DATE) }} -
                        {{ formatedDate(this.END_DATE) }} ({{
                            NIGHTS_INFO
                        }})</span
                    >
                </div>

                <div class="header-room" @click="SET_STEP(1)">
                    <i class="las la-user"></i>
                    <span>{{ BOOKING_INFO }}</span>
                </div>
            </div>

            <el-progress :percentage="75" :format="format()"></el-progress>
        </header>

        <!-- <div class="step-heading">
				<h2>Выберите путевку для каждого гостя</h2>
			</div> -->

        <BookDetailTable
            :dates="tableDates"
            :guests="tableGuests"
            :rooms="tableRooms"
            :tariffs="tableTariffs"
            :totalPrice="tableTotalPrice()"
            :key="price"
        />

        <div class="forms-block">
            <Forms
                @setSession="$emit('setSession')"
                @addingRoomsFinished="getPayType()"
                @returnToStart="$emit('returnToStart')"
                @removeAllRooms="removeAllRooms()"
                ref="formRef"
                :handlerRoomInfo="handlerRoomInfo"
                :scrollToTop="scrollToTop"
            />
        </div>

        <div class="info-cards-block">
            <div class="info-card" v-if="SETTINGS[0].show_cashback">
                <div class="card-content">
                    <p>
                        <b>Внимание!</b><br />Для получения кешбэка необходимо
                        выполнить все условия акции.
                    </p>
                    <ul>
                        <li>Регистрация в программе</li>
                        <li>Проживание минимум 3 дня</li>
                        <li>Даты проживания должны входить в период акции</li>
                    </ul>
                </div>
                <div class="card-footer">
                    <el-button type="link" @click="openMirRegistration"
                        >Зарегистрироваться для получения кэшбека</el-button
                    >
                    <el-button type="link" @click="handleInfoClick(5)">
                        Условия проведения акции
                    </el-button>
                </div>
            </div>

            <div class="info-cards-columns">
                <div class="info-card">
                    <p>Время заезда — 08:00<br />Время выезда — 08:00</p>
                    <p>
                        <a @click="handleInfoClick(0)" href="javascript:void(0)"
                            >Правила отмены бронирования</a
                        ><br />
                        <a @click="handleInfoClick(1)" href="javascript:void(0)"
                            >Перечень необходимых документов</a
                        >
                    </p>
                </div>
                <div class="info-card">
                    <p>
                        Осуществляя бронирование вы принимаете
                        <a @click="handleInfoClick(2)" href="javascript:void(0)"
                            >правила онлайн-бронирования</a
                        >
                        и соглашаетесь с
                        <a @click="handleInfoClick(3)" href="javascript:void(0)"
                            >обработкой персональных данных</a
                        >
                        <!-- и
                        <a @click="handleInfoClick(4)" href="javascript:void(0)"
                            >политикой конфиденциальности</a
                        > -->
                    </p>
                </div>
            </div>
        </div>
        <h2>Выберите способ оплаты</h2>

        <div
            class="payment-options"
            v-for="(item, idx) in paymentType"
            :key="idx"
        >
            <div class="payment-card with-sticker" v-if="item.PayType == 'MIR'">
                <div class="columns">
                    <div class="column description">
                        <h4>Оплата картой «Мир» с кешбэком</h4>
                        <p>
                            Оплата заказа онлайн через платежную систему
                            «Сбербанк». К оплате принимаются карты МИР.<br /><br />Оплачивается
                            <b>вся сумма</b> брони.
                        </p>
                    </div>
                    <div class="column summary">
                        <div class="methods">
                            <img src="img/mir.png" alt="" />
                        </div>
                        <div class="percent">Предоплата 100%</div>
                        <div class="price">{{ item.TotalSum }} ₽</div>
                        <el-button
                            type="primary"
                            @click="
                                saveAndPayment(item.Id);
                                scrollToTop();
                            "
                        >
                            Забронировать
                        </el-button>
                    </div>
                </div>
                <div class="stickers">
                    <div class="sticker clickable" @click="isInfoDrawer = true">
                        Кэшбек по карте МИР
                    </div>
                </div>
            </div>

            <div class="payment-card" v-if="item.PayType == 'CARD'">
                <div class="columns">
                    <div class="column description">
                        <h4>Оплата банковской картой</h4>
                        <p>
                            Оплата заказа онлайн через платежную систему
                            «Сбербанк». К оплате принимаются карты Visa, Visa
                            Electron, MasterCard, MasterCard Electronic, МИР.<br /><br />По
                            данному способу оплаты кешбэк по карте «Мир»
                            недоступен.
                        </p>
                    </div>
                    <div class="column summary">
                        <div class="methods">
                            <img src="img/mastercard.png" alt="" />
                            <img src="img/visa.png" alt="" />
                            <img src="img/mir.png" alt="" />
                        </div>
                        <div class="percent">
                            Предоплата {{ item.PayProc }}%
                        </div>
                        <div class="price">{{ item.PaySum }} ₽</div>
                        <el-button
                            type="primary"
                            @click="
                                saveAndPayment(item.Id);
                                scrollToTop();
                            "
                        >
                            Забронировать
                        </el-button>
                    </div>
                </div>
            </div>

            <div class="payment-card" v-if="item.PayType == 'NOPAY'">
                <div class="columns">
                    <div class="column description">
                        <h4>Без предоплаты</h4>
                        <p>
                            Оплата заказа осуществляется по прибытию. К оплате
                            принимаются карты Visa, Visa Electron, MasterCard,
                            MasterCard Electronic, МИР, возможна оплата
                            наличными.<br /><br />По данному способу оплаты
                            кешбэк по карте «Мир» недоступен.
                        </p>
                    </div>
                    <div class="column summary">
                        <div class="methods">
                            <img src="img/mastercard.png" alt="" />
                            <img src="img/visa.png" alt="" />
                            <img src="img/mir.png" alt="" />
                        </div>
                        <div class="percent">Без предоплаты</div>
                        <div class="price"></div>
                        <el-button
                            type="primary"
                            @click="
                                saveAndPayment(item.Id);
                                scrollToTop();
                            "
                        >
                            Забронировать
                        </el-button>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="payment-security" @click="$emit('returnToStart')">
            <img src="img/security.png" alt="" />
        </div> -->

        <!-- <div class="payment-guarantees">
            <p>
                Гарантии безопасности<br />Lorem ipsum
            </p>
        </div> -->
        <InfoDrawer
            :expand="isOpenInfo"
            :info="infoPages[pageIdx]"
            @collapseInfoDrawer="isOpenInfo = false"
        />

        <div class="footer-info">
            Не нашли интересующий вас вариант? Позвоните нам по телефону
            <a href="tel:8-800-707-5182">8 800 707 5182</a>, мы поможем.
        </div>
    </div>
</template>

<script>
import BookDetailTable from "./BookDetailTable";
import InfoDrawer from "./InfoDrawer";
import Forms from "./Forms";
import { mapActions, mapGetters } from "vuex";
import { formatDate, formatProgressBar } from "../api.js";
import { addEmail } from "../sendPulseApi";
import axios from "axios";
export default {
    components: {
        BookDetailTable,
        Forms,
        InfoDrawer,
    },
    props: {
        handlerRoomInfo: Function,
        scrollToTop: Function,
    },
    data() {
        return {
            guestParams: this.$store.state.guestParams,
            isInfoDrawer: false,
            paymentType: [],
            price: 0,
            isOpenInfo: false,
            infoPages: [],
            pageIdx: 0,
        };
    },
    created: async function () {
        await this.initStepFour();
    },
    mounted: async function () {
        //window.scrollTo(0, 0);
        this.scrollToTop();
        this.paymentType = [];
        // this.price = this.tableTotalPrice()
    },
    computed: {
        ...mapGetters([
            "START_DATE",
            "END_DATE",
            "DAYS",
            "NIGHTS_INFO",
            "BOOKING_INFO",
            "PAYMENT_TYPE_URL",
            "DIVISION",
            "GUEST_PARAMS",
            "ROOMS",
            "TARIFFS",
            "SESSION_DATA",
            "PAYMENT_URL",
            "SETTINGS",
            "BACK_API_URL",
            "ADDED_ROOMS",
            "REMOVE_URL",
        ]),
        tableDates: function () {
            let dates = `${this.formatedDate(
                this.START_DATE
            )} - ${this.formatedDate(this.END_DATE)}`;
            return dates;
        },
        tableGuests: function () {
            let adults = 0;
            let childs = 0;
            for (let idx = 0; idx < this.GUEST_PARAMS.length; idx++) {
                adults += this.GUEST_PARAMS[idx].adults.quantity;
                childs += this.GUEST_PARAMS[idx].childs.ages.length;
            }
            if (childs == 0) {
                return `${adults} взрослых`;
            }
            return `${adults} взрослых, ${childs} детей`;
        },
        tableRooms: function () {
            let roomsTitle = [];
            let result = "";
            for (let idx = 0; idx < this.GUEST_PARAMS.length; idx++) {
                const roomId = this.GUEST_PARAMS[idx].roomId;
                roomsTitle.push(this.getRoomTitle(idx, roomId));
            }
            for (let idx2 = 0; idx2 < roomsTitle.length; idx2++) {
                if (idx2 > 0) {
                    result += " | ";
                }
                result += `${roomsTitle[idx2]}`;
            }
            return result;
        },
        tableTariffs: function () {
            let tariffsIds = [];
            let result = "";
            for (let idx = 0; idx < this.GUEST_PARAMS.length; idx++) {
                const item = this.GUEST_PARAMS[idx];
                for (
                    let innerIdx = 0;
                    innerIdx < item.adults.tariffIds.length;
                    innerIdx++
                ) {
                    const tariff = item.adults.tariffIds[innerIdx];
                    tariffsIds.push(tariff);
                }
                for (
                    let innerIdx = 0;
                    innerIdx < item.childs.tariffIds.length;
                    innerIdx++
                ) {
                    const tariff = item.childs.tariffIds[innerIdx];
                    tariffsIds.push(tariff);
                }
            }
            for (let idx = 0; idx < tariffsIds.length; idx++) {
                const id = tariffsIds[idx].id;
                const title = this.getTariffTitle(id);
                if (idx > 0) {
                    result += " | ";
                }
                result += `${title}`;
            }
            return result;
        },
    },
    methods: {
        ...mapActions(["SET_STEP"]),
        formatedDate(Date) {
            return formatDate(Date);
        },
        format() {
            return formatProgressBar;
        },
        async getPayType() {
            const data = [
                {
                    division: this.DIVISION,
                    land: "ru",
                },
            ];
            const res = await axios.post(this.PAYMENT_TYPE_URL, data, {
                headers: { Authorization: `Bearer ${this.SESSION_DATA}` },
            });
            let respData = res.data;
            if (respData.result == "success") {
                this.paymentType = respData.data;
            }
        },
        getRoomTitle(roomIdx, roomId) {
            for (let idx = 0; idx < this.ROOMS.length; idx++) {
                if (
                    this.ROOMS[idx].idx == roomIdx &&
                    this.ROOMS[idx].id == roomId
                ) {
                    return this.ROOMS[idx].title;
                }
            }
        },
        getTariffTitle(id) {
            for (let idx = 0; idx < this.TARIFFS.length; idx++) {
                const tariffs = this.TARIFFS[idx].tariffs;
                for (let innerIdx = 0; innerIdx < tariffs.length; innerIdx++) {
                    const tariff = tariffs[innerIdx];
                    if (tariff.tariffId == id) {
                        return tariff.title;
                    }
                }
            }
        },
        async payment(paymentId) {
            let data = [
                {
                    paymentTypeId: paymentId,
                    division: this.DIVISION,
                    URL: "",
                },
            ];
            await axios
                .post(this.PAYMENT_URL, data, {
                    headers: { Authorization: `Bearer ${this.SESSION_DATA}` },
                })
                .then((res) => {
                    console.log("PAYMENT_REDIRECT_URL", res.data.redirectURL);
                    window.location.href = res.data.redirectURL;
                })
                .catch((err) => {
                    console.log("ERROR_MESSAGE", err.response.data.message);
                    if (err.request.status == 401) {
                        this.$emit("setSession");
                        this.payment(paymentId);
                    }
                });
        },
        async saveAndPayment(paymentId) {
            if (this.$refs.formRef.$v.contactForm.$invalid) {
                this.$refs.formRef.$v.contactForm.$touch();
                this.$refs.formRef.scrollToElement("formFirstLine");
                return -1;
            }
            this.$refs.formRef.saveToCart();

            if (this.$refs.formRef.checked) {
                this.addEmailSP();
            }
            await this.payment(paymentId);
        },
        openMirRegistration() {
            window.open("https://privetmir.ru/russiatravel/#frame");
        },
        tableTotalPrice() {
            let result = 0;
            for (let idx = 0; idx < this.GUEST_PARAMS.length; idx++) {
                const item = this.GUEST_PARAMS[idx];
                for (
                    let innerIdx = 0;
                    innerIdx < item.adults.tariffIds.length;
                    innerIdx++
                ) {
                    result += item.adults.tariffIds[innerIdx].price;
                }
                for (
                    let innerIdx = 0;
                    innerIdx < item.childs.tariffIds.length;
                    innerIdx++
                ) {
                    result += item.childs.tariffIds[innerIdx].price;
                }
            }
            this.price = result;
            return result;
        },
        async getInformationPages() {
            const url = `${this.BACK_API_URL}/informations/`;
            const resp = await axios(url, {
                method: "GET",
            });
            if (resp.data) {
                return resp.data;
            }
            return null;
        },
        async initStepFour() {
            this.infoPages = await this.getInformationPages();
        },
        handleInfoClick(idx) {
            this.pageIdx = idx;
            this.isOpenInfo = true;
            this.scrollToTop();
            // getAccessToken()
        },
        async addEmailSP() {
            const payerName = this.$refs.formRef.contactForm.payerName;
            const payerSecondName =
                this.$refs.formRef.contactForm.payerSecondName;
            const payerThirdName =
                this.$refs.formRef.contactForm.payerThirdName;
            const phone = this.$refs.formRef.contactForm.phone;
            const email = this.$refs.formRef.contactForm.email;

            const variables = {
                Имя: payerName,
                Отчество: payerThirdName,
                Фамилия: payerSecondName,
                Phone: phone,
            };

            const data = {
                emails: [
                    {
                        email: email,
                        variables: variables,
                    },
                ],
            };
            addEmail(data);
        },
        getClusterId(idx) {
            let clasterIds = [];
            const item = this.ADDED_ROOMS[idx];
            clasterIds.push({ clusterId: item.room.clusterId });
            return clasterIds;
        },
        async removeRoom(idx) {
            let clasterId = this.getClusterId(idx);
            await axios
                .post(this.REMOVE_URL, clasterId, {
                    headers: { Authorization: `Bearer ${this.SESSION_DATA}` },
                })
                .then((res) => {
                    console.log("REMOVE ROOM RESP", res);
                })
                .catch((err) => {
                    console.log("ERROR_MESSAGE", err.response.data.message);
                    if (err.request.status == 400) {
                        this.$emit("returnToStart");
                    }
                    if (err.request.status == 401) {
                        this.$emit("setSession");
                        setTimeout(this.removeRoom, 1000);
                    }
                    if (err.request.status == 500) {
                        this.$emit("setSession");
                        setTimeout(this.removeRoom, 2000);
                    }
                });
        },
        removeAllRooms() {
            for (let idx = 0; idx < this.GUEST_PARAMS.length; idx++) {
                this.removeRoom(idx)
            }
        },
        async goToStep3() {
            this.SET_STEP(3);
            await this.removeAllRooms();
            this.$store.commit("SET_ADDED_ROOMS", null);
        },
    },
};
</script>

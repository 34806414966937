<template>
    <transition name="slide">
        <div class="list-view upgrade-room" v-if="updateRoom && !isChoosen">
            <div class="room">
                <div class="room-header">
                    <i class="las la-angle-double-up"></i>
                    <span>Повысить комфорт</span>
                </div>
                <div class="item">
                    <div class="picture">
                        <img :src="thumbnails2x" alt="" />
                        <div class="features">
                            <div
                                v-for="(
                                    item, index
                                ) in updateRoom.main_equipment"
                                :key="index"
                            >
                                <i class="las" :class="item.icon"></i>
                            </div>
                        </div>
                    </div>
                    <div class="description">
                        <div class="main column">
                            <div class="title">
                                <div v-html="updateRoom.title"></div>
                            </div>
                            <div class="properties">
                                <div class="last-room">
                                    <!---->
                                </div>
                                <div>
                                    <div>
                                        <i class="las la-ruler-combined"></i>
                                        <span> {{ updateRoom.area }} м²</span>
                                    </div>
                                    <div>
                                        <i class="las la-user-friends"></i>
                                        <span>
                                            до
                                            {{ updateRoom.seats_number }}
                                            мест</span
                                        >
                                    </div>
                                </div>

                                <div>
                                    <div
                                        class="link"
                                        @click="
                                            handlerRoomInfo(
                                                updateRoom.id_zdravnitsa
                                            )
                                        "
                                    >
                                        Описание номера
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="footer column">
                            <div class="price">
                                Всего <span>+{{ diffPrice() }}₽</span> за
                                {{ DAYS }} дней
                            </div>

                            <el-button
                                class="choose"
                                type="primary"
                                @click="
                                    upsellRoom();
                                    scrollToTop();
                                "
                                >Выбрать</el-button
                            >
                        </div>

                        <div class="stickers" v-if="updateRoom.sales_leader">
                            <div class="sticker">Лидер продаж</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import axios from "axios";
import EventBus from "../event-bus.js";
export default {
    props: {
        roomNumber: Number,
        roomId: Number,
        handlerRoomInfo: Function,
        scrollToTop: Function,
    },
    data() {
        return {
            number: this.roomNumber,
            isChoosen: false,
            thumbnails2x: null,
            updateRoom: null,
        };
    },
    mounted: async function () {
        this.updateRoom = await this.getUpdateRoom(this.roomId);
        if (this.updateRoom) {
            let photos = await this.getRoomPhoto(this.updateRoom.id);
            if (photos.length > 0) {
                this.thumbnails2x = photos[0].thumbnail_2x;
            } else {
                this.thumbnails2x = "img/placeholder.png";
            }
        }
        EventBus.$on("upsellRoom", () => {
            this.upsellRoom();
        });
    },
    computed: {
        ...mapState(["backApiUrl"]),
        ...mapGetters([
            "BACKEND_ROOMS",
            "CHOOSEN_ROOMS",
            "CHOOSEN_TARIIFS",
            "BACK_API_URL",
            "DAYS",
            "GUEST_PARAMS",
            "SEARCH_DATA",
            "PRICES",
            "ADDED_ROOMS",
            "REMOVE_URL",
        ]),
        // currentRoom: function() {
        //     return this.getRoomByIdx(this.roomNumber)
        // },
        // updateRoom: function () {
        //     return this.getUpdateRoom(this.id);
        // },
    },
    methods: {
        ...mapActions(["SET_GUEST_PARAMS"]),
        getUpdateRoom(id) {
            let ids = this.getUpdateRoomIds(id);
            for (let idx = 0; idx < ids.length; idx++) {
                let updateId = ids[idx];
                if (this.isAvailableForUpdate(updateId)) {
                    return this.getBackendRoomById(updateId);
                }
            }
            return null;
        },
        getPrice(roomId, tariffId, age, isRoom) {
            for (let idx = 0; idx < this.PRICES.length; idx++) {
                let item = this.PRICES[idx];
                if (
                    item.roomId === roomId &&
                    item.tariffId === tariffId &&
                    item.age === age &&
                    item.isRoom === isRoom
                ) {
                    return item.price;
                }
            }
            return null;
        },
        diffPrice() {
            let guestParam = this.GUEST_PARAMS[this.number];
            let currentPrice = 0;
            let updatePrice = 0;
            let updateRoomId = this.getUpdateRoom(this.roomId).id_zdravnitsa;
            for (let idx = 0; idx < guestParam.adults.tariffIds.length; idx++) {
                let adultTariff = guestParam.adults.tariffIds[idx];
                currentPrice += adultTariff.price;
                updatePrice += this.getPrice(
                    updateRoomId,
                    adultTariff.id,
                    -1,
                    adultTariff.isRoom
                );
            }
            for (
                let idx2 = 0;
                idx2 < guestParam.childs.tariffIds.length;
                idx2++
            ) {
                let childTariff = guestParam.childs.tariffIds[idx2];
                currentPrice += childTariff.price;
                let age = guestParam.childs.ages[idx2];
                updatePrice += this.getPrice(
                    updateRoomId,
                    childTariff.id,
                    age,
                    childTariff.isRoom
                );
            }
            return updatePrice - currentPrice;
        },
        isAvailableForUpdate(updateId) {
            //логика работы функции такая, что проверяется основное/дополнительное место.
            // если постеитель изначально выбрал доп. место, то ему будет предложен только номер с таким же тарифом и размещением на доп. месте
            let rooms = this.SEARCH_DATA[this.number].availableRoom;
            let currentTariffId =
                this.GUEST_PARAMS[this.number].adults.tariffIds[0].id;
            let isRoom =
                this.GUEST_PARAMS[this.number].adults.tariffIds[0].isRoom;
            let backendRoom = this.getBackendRoomById(updateId);
            let idZdravnitsa = backendRoom.id_zdravnitsa;
            for (let innerIdx = 0; innerIdx < rooms.length; innerIdx++) {
                let roomId = rooms[innerIdx].id;
                if (roomId === idZdravnitsa) {
                    let tariffs = rooms[innerIdx].guests[0].tariffs;
                    if (this.hasTariff(currentTariffId, tariffs, isRoom)) {
                        return true;
                    }
                }
            }
            return false;
        },
        hasTariff(id, tariffs, isRoom) {
            for (let idx = 0; idx < tariffs.length; idx++) {
                if (
                    tariffs[idx].tariffId === id &&
                    tariffs[idx].isRoom === isRoom
                ) {
                    return true;
                }
            }
            return false;
        },

        getUpdateRoomIds(id) {
            let ids = [];
            let room = this.getBackendRoomByZdravId(id);
            if (room != null) {
                if (room.update_room1 != null) {
                    ids.push(room.update_room1);
                }
                if (room.update_room2 != null) {
                    ids.push(room.update_room2);
                }
            }
            return ids;
        },
        getBackendRoomByZdravId(id) {
            let rooms = this.BACKEND_ROOMS;
            for (let idx = 0; idx < rooms.length; idx++) {
                let room = rooms[idx];
                if (room.id_zdravnitsa == id) {
                    return room;
                }
            }
            return null;
        },
        getBackendRoomById(id) {
            let rooms = this.BACKEND_ROOMS;
            for (let idx = 0; idx < rooms.length; idx++) {
                let room = rooms[idx];
                if (room.id == id) {
                    return room;
                }
            }
            return null;
        },
        async upsellRoom() {
            this.isChoosen = true;
            let guestParams = this.GUEST_PARAMS;
            let param = guestParams[this.number];
            let roomId = this.updateRoom.id_zdravnitsa;
            param.roomId = roomId;
            for (let idx = 0; idx < param.adults.tariffIds.length; idx++) {
                let tariff = param.adults.tariffIds[idx];
                tariff.price = this.getPrice(
                    roomId,
                    tariff.id,
                    -1,
                    tariff.isRoom
                );
            }
            for (let idx2 = 0; idx2 < param.childs.tariffIds.length; idx2++) {
                let tariff = param.childs.tariffIds[idx2];
                tariff.price = this.getPrice(
                    roomId,
                    tariff.id,
                    param.childs.ages[idx2],
                    tariff.isRoom
                );
            }
            await this.SET_GUEST_PARAMS(guestParams);
            this.$emit("syncRoom");
            this.showMessage(`Номер успешно обновлён`, "success");
        },
        showMessage(msg, msgType) {
            this.$message({
                message: msg,
                type: msgType,
                duration: 4000,
            });
        },
        async getRoomPhoto(roomId) {
            const url = `${this.backApiUrl}/photos/`;

            const resp = await axios(url, {
                method: "GET",
                params: { room_id: roomId },
            });
            if (resp.data) {
                return resp.data;
            }
            return null;
        },
    },
};
</script>
<!-- <style lang="css">
.fade-enter-active,
.fade-leave-active {
    transition: opacity 3s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.slide-enter-active {
    -moz-transition-duration: 3s;
    -webkit-transition-duration: 3s;
    -o-transition-duration: 3s;
    transition-duration: 3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}

.slide-leave-active {
   transition-timing-function: cubic-bezier(0.42,0,0.58,1);
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}

.slide-enter-to,
.slide-leave {
    max-height: 100px;
    overflow: hidden;
}

.slide-enter,
.slide-leave-to {
    overflow: hidden;
    max-height: 0;
}
</style>
 -->

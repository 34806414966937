<template>
    <el-select
        v-model="value"
        placeholder="Добавить взрослого"
        @change="selectChanged($event)"
    >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >
        </el-option>
    </el-select>
</template>

<script>
export default {
    props: {
        selectedValue: Number,
    },
    data() {
        return {
            options: [
                {
                    value: 1,
                    label: "1 Взрослый",
                },
                {
                    value: 2,
                    label: "2 Взрослых",
                },
                {
                    value: 3,
                    label: "3 Взрослых",
                },
                {
                    value: 4,
                    label: "4 Взрослых",
                },
                {
                    value: 5,
                    label: "5 Взрослых",
                },
            ],
            value: this.selectedValue,
        };
    },
    methods: {
        selectChanged(value) {
            this.$emit("selectChanged", value);
        },
    },
};
</script>
